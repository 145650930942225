import {JsonApiRecord, PropTypes} from "@/records/JsonApiRecord";
import {Indicator} from "@/records/Indicator";

const attributes = {
    maleSum: PropTypes.number,
    femaleSum: PropTypes.number,
    maleTotalAverage: PropTypes.number,
    femaleTotalAverage: PropTypes.number
};

const toOneRelationships = {
    indicator: () => Indicator
};

export class IndicatorAggregate extends JsonApiRecord('indicator-aggregates', '',
    attributes, toOneRelationships) {
    static get modelName() {
        return 'IndicatorAggregate';
    }
}