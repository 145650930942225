import React from 'react';
import {connect} from 'react-redux';

import LoginComponent from '@vallarj/react-adminlte/Login';
import {getLoginRequest, initSession, postLoginRequest} from "@/utilities/auth";
import {toast} from "react-toastify";
import ToastMessage from "@/components/ToastMessage";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginChallenge: null,
            csrf: null,
            show: false
        };
        this.toastRef = React.createRef();
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);

        if(!params.has("login_challenge")) {
            initSession()
                .then(loggedIn => {
                    if (loggedIn) {
                        this.props.history.replace("/");
                    }
                });
        } else {
            this.setState({loginChallenge: params.get("login_challenge")});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {loginChallenge} = this.state;
        if(loginChallenge && prevState.loginChallenge !== loginChallenge) {
            this.performLoginRequest();
        }
    }

    performLoginRequest = () => {
        const {loginChallenge} = this.state;
        getLoginRequest(loginChallenge)
            .then(({data}) => {
                if(data["status_code"] === 404) {
                    // Invalid login challenge
                    window.location.replace("/login");
                } else if(data["status_code"] >= 400) {
                    this.setState({
                        show: true,
                        csrf: data["state"]
                    });
                } else if(data["status_code"] === 302) {
                    window.location.replace(data["redirect_to"]);
                }
            });
    };

    handleSubmit = (username, password) => {
        const {loginChallenge, csrf} = this.state;

        postLoginRequest(loginChallenge, username, password, csrf)
            .then(({data}) => {
                if(data["status_code"] === 401) {
                    // Invalid username/password
                    toast.dismiss();

                    this.toastRef.current = toast.error(
                        <ToastMessage title="Failed to log-in"
                                      message="Invalid username or password."/>, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    this.setState({csrf: data["state"]});
                } else if(data["status_code"] === 404) {
                    // This must be an invalid login challenge
                    window.location.replace("/login");
                } else if (data["status_code"] >= 400) {
                    // TODO: Handle this.
                } else if(data["status_code"] === 302) {
                    window.location.replace(data["redirect_to"]);
                }
            });
    };

    render() {
        const {show} = this.state;

        if(!show) {
            return null;
        }

        return (
            <>
                <LoginComponent title={<strong>BJMP - DHS Health Reporting System</strong>}
                                onSubmit={this.handleSubmit}
                                clearPasswordOnSubmit/>
            </>
        );
    }
}

export default connect()(Login);