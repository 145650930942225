import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, Redirect} from 'react-router-dom';
import Login from "@/screens/Login";
import Consent from "@/screens/Consent";
import UserLayout from "@/UserLayout";
import {fetchSystemTime} from "@/actions/globalActions";
import {AbilityContext, userAbility} from "@/ability";
import {ToastContainer} from "react-toastify";
import ScrollToTop from "@/components/ScrollToTop/ScrollToTop";

class Main extends React.Component {
    componentDidMount() {
        fetchSystemTime();
    }

    render() {
        return (<>
            <ToastContainer/>
            <ScrollToTop/>
            <Switch>
                <Route exact path='/login' component={Login}/>
                <Route exact path='/consent' component={Consent}/>
                <Route render={props => (
                    <AbilityContext.Provider value={userAbility}>
                        <UserLayout {...props}/>
                    </AbilityContext.Provider>
                )}/>
                <Redirect to='/login'/>
            </Switch>
        </>);
    }
}


export default connect()(Main);