import axios from 'axios';
import {config} from "@/config";
import {authSetAccessToken, authSetCheckedAuth, authSetLoggedIn, authSetUser} from "@/actions/authActions";
import store from "@/store";

const LOGIN_ENDPOINT = `${config.publicURL}/auth/login`;
const CONSENT_ENDPOINT = `${config.publicURL}/auth/consent`;

export const getLoginRequest = challenge => {
    const params = {login_challenge: challenge};
    return axios.get(LOGIN_ENDPOINT, {params});
};

export const postLoginRequest = (challenge, username, password, csrf) => {
    const params = {
        login_challenge: challenge,
        username,
        password,
        state: csrf
    };
    return axios.post(LOGIN_ENDPOINT, params);
};

export const getConsentRequest = challenge => {
    const params = {consent_challenge: challenge};
    return axios.get(CONSENT_ENDPOINT, {params});
};

export const postConsentRequest = (challenge, allow, csrf) => {
    const params = {
        consent_challenge: challenge,
        allow,
        state: csrf
    };
    return axios.post(CONSENT_ENDPOINT, params);
}

export const getSession = () => axios.get('/session');

export function initSession() {
    return getSession()
        .then(({data}) => {
            store.dispatch(authSetCheckedAuth(true));

            if (!data["logged_in"]) {
                window.location.replace(data["redirect_to"]);
            } else {
                store.dispatch(authSetLoggedIn(true));
                store.dispatch(authSetAccessToken(data["access_token"], data["expires"]));
                store.dispatch(authSetUser({id: data["user_id"], ...data["usr"]}));
                return true;
            }

            return false;
        });
}