import React from 'react';
import {connect} from "react-redux";

import BreadcrumbItem from '@vallarj/react-adminlte/Content/BreadcrumbItem';
import Content from '@vallarj/react-adminlte/Content';
import {createCancelToken, fetchResourceCollection, waitForAllRequests} from "@/utilities/jsonapi";
import {IndicatorAggregate} from "@/records/IndicatorAggregate";
import {config} from "@/config";
import {Indicator} from "@/records/Indicator";
import PopulationStatistics from "@/screens/Dashboard/PopulationStatistics";
import HealthcareSystem from "@/screens/Dashboard/HealthcareSystem";
import RiskFactors from "@/screens/Dashboard/RiskFactors";
import Form, {CalendarInput, SelectInput} from "@vallarj/react-adminlte/Form";
import {resolveCurrentSystemTime} from "@/actions/globalActions";
import moment from "moment";
import DiseaseScreening from "@/screens/Dashboard/DiseaseScreening";
import {IndicatorAggregateContext} from "@/screens/Dashboard/utilities";
import Treatment from "@/screens/Dashboard/Treatment";
import {Office} from "@/records/Office";
import {OFFICE_TYPE_HQ, OFFICE_TYPE_JAIL_LEVEL, OFFICE_TYPE_REGIONAL} from "@/permissions";
import {AbilityContext} from "@/ability";
import ActionsDropdownButton from "@/screens/Dashboard/ActionsDropdownButton";
import Box from "@vallarj/react-adminlte/Box";

const DISCLAIMER = "Caution is advised in the interpretation of the following ratio, proportion, and " +
    "percentages. The data and information in the data set provided here are intended for use by persons " +
    "possessing technical skill and knowledge in epidemiology, surveillance and data management."

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.fetchCancelToken = createCancelToken();
        this.state = {
            indicatorMap: null,
            indicatorAggregateContext: {
                indicatorTagMap: {},
                indicatorAggregateMap: {}
            },
            fromDate: null,
            toDate: null,
            office: null,
            offices: [],
            allOffices: [],
            region: null,
            regions: [],
            isLoading: true,
            currentDate: null,
            showBackToTop: false,
        };
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const fromDate = searchParams.get("from");
        const toDate = searchParams.get("to");
        const paramOffice = searchParams.get("office");
        const time = resolveCurrentSystemTime();

        this.setState({
            isLoading: true,
            currentDate: time
        });
        fetchResourceCollection(Office)
            .onSuccess(offices => {
                const {officeType, officeId} = this.props;
                let regions;
                let region;
                const currentOffice = offices.find(o => o.id === officeId);
                offices = offices.filter(o => o.officeType !== OFFICE_TYPE_HQ);
                let allOffices = offices;
                regions = offices.filter(o => o.officeType === OFFICE_TYPE_REGIONAL);

                if (officeType === OFFICE_TYPE_HQ) {
                    region = currentOffice;
                    regions = [currentOffice, ...regions];
                    offices = [currentOffice];
                } else if (officeType === OFFICE_TYPE_REGIONAL) {
                    regions = regions.filter(r => r.id === officeId);
                    region = currentOffice;
                    offices = [currentOffice, ...offices.filter(o => o.parent && o.parent.id === region.id)];
                    allOffices = offices;
                } else {
                    regions = regions.filter(r => currentOffice.parent.id === r.id);
                    region = regions[0];
                    offices = allOffices = [currentOffice];
                }

                let office;
                if (paramOffice) {
                    if (currentOffice.id === paramOffice) {
                        office = currentOffice;
                    } else {
                        office = allOffices.find(o => o.id === paramOffice);
                    }
                } else {
                    office = offices[0];
                }

                if (office) {
                    window.__dashboardOffice = office.name;
                }

                this.setState({
                    fromDate: fromDate ? moment(fromDate) : moment({year: time.year(), month: 0, day: 1}),
                    toDate: toDate ? moment(toDate) : time,
                    allOffices,
                    office,
                    offices,
                    region,
                    regions,
                });
            })
            .execute(this.fetchCancelToken);

        window.addEventListener("scroll", this.onScroll);
    }

    componentWillUnmount() {
        this.fetchCancelToken.cancel();
        window.removeEventListener("scroll", this.onScroll);
    }

    onScroll = () => {
        this.setState({
            showBackToTop: window.pageYOffset > 100
        });
    };

    scrollToTop = () => {
        window.scrollTo(0, 0);
        this.setState({
            showBackToTop: false
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fromDate, toDate, office} = this.state;

        if (prevState.office !== office || prevState.fromDate !== fromDate || prevState.toDate !== toDate) {
            this.fetchIndicatorAggregates();
        }
    }

    fetchIndicatorAggregates = () => {
        const {indicatorMap, fromDate, toDate, office} = this.state;
        if (!fromDate || !toDate || !office) {
            return null;
        }

        const params = {
            f: fromDate.format('YYYY-MM-DD'),
            t: toDate.format('YYYY-MM-DD')
        };

        let endpointSubPath;
        switch (office.officeType) {
            case OFFICE_TYPE_HQ:
                endpointSubPath = 'headquarters';
                break;
            case OFFICE_TYPE_REGIONAL:
                endpointSubPath = 'region';
                break;
            case OFFICE_TYPE_JAIL_LEVEL:
                endpointSubPath = 'jail-level-office';
                break;
            default:
                return;
        }
        const indicatorAggregatesEndpoint = `${config.apiBaseUrl}/reports/indicator-aggregates/` +
            `${endpointSubPath}/${office.id}`;

        this.setState({isLoading: true});
        this.fetchCancelToken.cancel();
        this.fetchCancelToken = createCancelToken();

        if (!indicatorMap) {
            waitForAllRequests([
                fetchResourceCollection(Indicator),
                fetchResourceCollection(IndicatorAggregate, params, indicatorAggregatesEndpoint),
            ]).onSuccess(([indicators, indicatorAggregates]) => {
                const indicatorTagMap = {};
                const indicatorMap = {};
                indicators.forEach(indicator => {
                    if (indicator.tag) {
                        indicatorTagMap[indicator.tag] = indicator;
                    }
                    indicatorMap[indicator.id] = indicator;
                });

                const indicatorAggregateMap = this.mapIndicatorAggregates(indicatorAggregates, indicatorMap);
                this.setState({
                    indicatorMap,
                    indicatorAggregateContext: {
                        indicatorTagMap,
                        indicatorAggregateMap
                    },
                    isLoading: false,
                });
            }).execute(this.fetchCancelToken);
        } else {
            fetchResourceCollection(IndicatorAggregate, params, indicatorAggregatesEndpoint)
                .onSuccess(indicatorAggregates => {
                    const indicatorAggregateMap = this.mapIndicatorAggregates(indicatorAggregates, indicatorMap);
                    this.setState({
                        indicatorAggregateContext: {
                            ...this.state.indicatorAggregateContext,
                            indicatorAggregateMap
                        },
                        isLoading: false
                    });
                })
                .execute(this.fetchCancelToken);
        }
    };

    mapIndicatorAggregates = (indicatorAggregates, indicatorMap) => {
        const indicatorAggregateMap = {};
        indicatorAggregates.forEach(aggregate => {
            const indicator = indicatorMap[aggregate.indicator.id];
            const {maleSum, femaleSum, maleTotalAverage, femaleTotalAverage} = aggregate;

            let reportMale = 0, reportFemale = 0, reportTotal = 0;
            switch (indicator.summaryType) {
                case "sum":
                    reportMale = maleSum;
                    reportFemale = femaleSum;
                    reportTotal = maleSum + femaleSum;
                    break;
                case "average":
                    reportMale = maleTotalAverage;
                    reportFemale = femaleTotalAverage;
                    reportTotal = maleTotalAverage + femaleTotalAverage;
                    break;
            }

            const mappedAggregate = {
                maleSum,
                femaleSum,
                maleTotalAverage,
                femaleTotalAverage,
                reportMale,
                reportFemale,
                reportTotal
            };
            aggregate = aggregate.set('indicator', indicatorMap[aggregate.indicator.id]);
            indicatorAggregateMap[aggregate.indicator.id] = mappedAggregate;
        });
        return indicatorAggregateMap;
    };

    handleFilterChange = (field, value) => {
        const {allOffices} = this.state;
        if (field === 'region') {
            let offices = [];
            if (value.officeType === OFFICE_TYPE_HQ) {
                offices = [value];
            } else {
                offices = [value, ...allOffices.filter(o => o.parent && o.parent.id === value.id)];
            }
            this.setState({
                offices,
                office: offices[0]
            });
        }

        this.setState({
            [field]: value
        });
    };

    getRegionLabelByName = option => option.officeType === OFFICE_TYPE_HQ ? 'All Regions' : option.name;
    getDataLabelByName = option => option.officeType === OFFICE_TYPE_HQ ? 'All Jails' : option.name;
    getValueById = option => option.id;

    fromDateValidator = current => {
        const {toDate} = this.state;
        return current.isSameOrBefore(toDate, 'day');
    };

    toDateValidator = current => {
        const {fromDate, currentDate} = this.state;
        return current.isSameOrBefore(currentDate, 'day')
            && current.isSameOrAfter(fromDate, 'day');
    };

    render() {
        const {
            indicatorAggregateContext, fromDate, toDate, regions, region, offices, office,
            isLoading, showBackToTop
        } = this.state;

        return (
            <Content>
                <Content.Header title="Overall Dashboard"/>
                <Content.Breadcrumb>
                    <BreadcrumbItem label="Overall Dashboard" iconClass="fa fa-dashboard" active/>
                </Content.Breadcrumb>
                <Content.Body>
                    <div className="j-dash-filter-controls">
                        <div>
                            <div className="row">
                                <Form onChange={this.handleFilterChange}>
                                    <div className="col-xs-3">
                                        <SelectInput label="Region" name="region" value={region}
                                                     options={regions}
                                                     getOptionLabel={this.getRegionLabelByName}
                                                     getOptionValue={this.getValueById}/>
                                    </div>
                                    <div className="col-xs-3">
                                        <SelectInput label="Data" name="office" value={office}
                                                     options={offices}
                                                     getOptionLabel={this.getDataLabelByName}
                                                     getOptionValue={this.getValueById}/>
                                    </div>
                                    <div className="col-xs-3">
                                        <CalendarInput name="fromDate" label="From" timePicker={false}
                                                       value={fromDate} clearable={false}
                                                       isSelectableDate={this.fromDateValidator}/>
                                    </div>
                                    <div className="col-xs-3">
                                        <CalendarInput name="toDate" label="To" timePicker={false}
                                                       value={toDate} clearable={false}
                                                       isSelectableDate={this.toDateValidator}/>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div>
                            <ActionsDropdownButton office={office} fromDate={fromDate} toDate={toDate}/>
                        </div>
                    </div>
                    <div className="j-dash-disclaimer">
                        <Box theme="box-primary">
                            <Box.Header title="Disclaimer"/>
                            <Box.Body>
                                {DISCLAIMER}
                            </Box.Body>
                        </Box>
                    </div>
                    <IndicatorAggregateContext.Provider value={indicatorAggregateContext}>
                        <PopulationStatistics office={office} fromDate={fromDate} toDate={toDate} isLoading={isLoading}/>
                        <HealthcareSystem/>
                        <div className="j-dash-risk-factors j-dash-print-force-br">
                            <RiskFactors office={office} fromDate={fromDate} toDate={toDate} isLoading={isLoading}/>
                        </div>
                        <DiseaseScreening office={office} fromDate={fromDate} toDate={toDate} isLoading={isLoading}/>
                        <div className="j-dash-print-force-br">
                            <Treatment office={office} fromDate={fromDate} toDate={toDate} isLoading={isLoading}/>
                        </div>
                    </IndicatorAggregateContext.Provider>
                    {
                        showBackToTop &&
                        <div className="j-scroll-to-top-container">
                            <button className="j-scroll-to-top" onClick={this.scrollToTop}>
                                <i className="fa fa-arrow-up"/>
                            </button>
                        </div>
                    }
                </Content.Body>
            </Content>
        );
    }
}

function mapStateToProps({auth}) {
    return {
        officeId: auth.user.office.id,
        officeType: auth.user.office.office_type
    };
}

Dashboard.contextType = AbilityContext;
export default connect(mapStateToProps)(Dashboard);